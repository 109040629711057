
export const CatList = [
  // Categories
  {
    name: "Exclusive Games",
    related:"",
    path: ["/categories/play-cute-games"],
    thumb:"/assets/cat/exclusive-games-icon.png",
  },
  {
    name: "Christmas games",
    related:"",
    path: ["/categories/christmas-games"],
    thumb:"/assets/cat/christmas-games.png",
  },
   {
    name: "Halloween games",
    related:"",
    path: ["/categories/halloween-games"],
    thumb:"/assets/cat/halloween-games.png",
  },
  {
    name: "Princess Games",
    related:"",
    path: ["/categories/princess-games"],
    thumb:"/assets/cat/princess-games-icon.png",
  },
  {
    name: "Dress up Games",
    related:"",
    path: ["/categories/dress-up-games"],
    thumb:"/assets/cat/dressup-games.png",
  },
  {
    name: "Gamezop Games",
    related:"",
    path: ["/categories/gamezop-games"],
    thumb:"/assets/cat/gamezop-games.png",
  },
  {
    name: "Make Up Games",
    related:"",
    path: ["/categories/makeover-games"],
    thumb:"/assets/cat/girl-games-icon.png",
  },
  {
    name: "Decoration Games",
    related:"",
    path: ["/categories/decoration-games"],
    thumb:"/assets/cat/decoration-games.png",
  },

  {
    name: "Toca Boca",
    related:"",
    path: ["/categories/toca-boca"],
    thumb:"/assets/cat/toca-boca.png",
  },
  {
    name: "Wedding Games",
    related:"",
    path: ["/categories/wedding-games"],
    thumb:"/assets/cat/wedding-games.png",
  },
  {
    name: "Celebrity Games",
    related:"",
    path: ["/categories/celebrity-games"],
    thumb:"/assets/cat/celebrity-games.png",
  },
  {
    name: "Cooking Games",
    related:"",
    path: ["/categories/cooking-games"],
    thumb:"/assets/cat/cooking-games.png",
  },
  {
    name: "Doctor Games",
    related:"",
    path: ["/categories/doctor-games"],
    thumb:"/assets/cat/doctor-games.png",
  },
  {
    name: "FNF Games",
    related:"",
    path: ["/categories/friday-night-funkin"],
    thumb:"/assets/cat/friday-night-funkin.png",
  },
  {
    name: "Dl-Girls Games",
    related:"",
    path: ["/categories/dl-girls"],
    thumb:"/assets/cat/dl-girl-games.png",
  },
  {
    name: "Dressupwho Games",
    related:"",
    path: ["/categories/dressupwho-games"],
    thumb:"/assets/cat/dressupwho-games.png",
  },
  {
    name: "Girls Go Games",
    related:"",
    path: ["/categories/girlsgogames"],
    thumb:"/assets/cat/prinxy-games.png",
  },
  {
    name: "Prinxy Games",
    related:"",
    path: ["/categories/prinxy-games"],
    thumb:"/assets/cat/prinxy-games.png",
  },
  {
    name: "Winx club Games",
    related:"",
    path: ["/categories/winx-club-games"],
    thumb:"/assets/cat/winx-club-games.png",
  },
  // Categories
  {
    name: "Fashion Games",
    related:"",
    path: ["/categories/fashion-games"],
    thumb:"/assets/cat/fashion-games.png",
  },
  {
    name: "Girl Games",
    related:"",
    path: ["/categories/girl-games"],
    thumb:"/assets/cat/girl-games.png",
  },
  {
    name: "E-girl Games",
    related:"",
    path: ["/categories/egirl-games"],
    thumb:"/assets/cat/egirl-games.png",
  },
  {
    name: "Girl Dressup Games",
    related:"",
    path: ["/categories/girldressupgames"],
    thumb:"/assets/cat/girldressupgames.png",
  },
  {
    name: "Games For Girls",
    related:"",
    path: ["/categories/games-for-girls"],
    thumb:"/assets/cat/girldressupgames.png",
  },
  {
    name: "Shopaholic Games",
    related:"",
    path: ["/categories/shopaholic-games"],
    thumb:"/assets/cat/shopaholic-games.png",
  },
  {
    name: "My Dolphin Show",
    related:"",
    path: ["/categories/my-dolphin-show"],
    thumb:"/assets/cat/my-dolphin-show.png",
  },
  {
    name: "Mermaid Games",
    related:"",
    path: ["/categories/mermaid-games"],
    thumb:"/assets/cat/mermaid-games.png",
  },
  {
    name: "Pony Games",
    related:"",
    path: ["/categories/pony-games"],
    thumb:"/assets/cat/pony-games.png",
  },
  {
    name: "Yad Games",
    related:"",
    path: ["/categories/yad-games"],
    thumb:"/assets/cat/yad-games.png",
  },
  {
    name: "Yiv Games",
    related:"",
    path: ["/categories/yiv-games"],
    thumb:"/assets/cat/yiv-games.png",
  },
  {
    name: "Poki Games",
    related:"",
    path: ["/categories/poki-games"],
    thumb:"/assets/cat/poki-games.png",
  },
  {
    name: "Crazy Games",
    related:"",
    path: ["/categories/crazy-games"],
    thumb:"/assets/cat/crazy-games.png",
  },
  {
    name: "Lagged Games",
    related:"",
    path: ["/categories/lagged-games"],
    thumb:"/assets/cat/lagged-games.png",
  },
  {
    name: "Dentist Games",
    related:"",
    path: ["/categories/dentist-games"],
    thumb:"/assets/cat/dentist-games.png",
  },
  {
    name: "Lol Surprise Games",
    related:"",
    path: ["/categories/lol-surprise-games"],
    thumb:"/assets/cat/lol-surprise-games.png",
  },
  {
    name: "Rainbow High Dolls",
    related:"",
    path: ["/categories/rainbow-high-dolls"],
    thumb:"/assets/cat/rainbow-high-dolls-games.png",
  },
  {
    name: "Nail Games",
    related:"",
    path: ["/categories/nail-games"],
    thumb:"/assets/cat/nail-games.png",
  },
  {
    name: "Match 3 Games",
    related:"",
    path: ["/categories/match-3-games"],
    thumb:"/assets/cat/match-3-games.png",
  },
  {
    name: "3D Games",
    related:"",
    path: ["/categories/3d-games"],
    thumb:"/assets/cat/3d-games.png",
  },
  {
    name: "ufreegames",
    related:"",
    path: ["/categories/ufreegames"],
    thumb:"/assets/cat/ufreegames.png",
  },
  {
    name: "Barbie Dress Up",
    related:"",
    path: ["/categories/barbie-dress-up"],
    thumb:"/assets/cat/barbie-dress-up.png",
  },
  {
    name: "FNF GF",
    related:"",
    path: ["/categories/fnf-gf"],
    thumb:"/assets/cat/barbie-dress-up.png",
  },
  {
    name: "Friday Night Funkin",
    related:"",
    path: ["/categories/friday-night-funkin-girlfriend"],
    thumb:"/assets/cat/barbie-dress-up.png",
  },
  {
    name: "FNF Girlfriend",
    related:"",
    path: ["/categories/friday-night-funkin-girlfriend"],
    thumb:"/assets/cat/barbie-dress-up.png",
  },
  {
    name: "Dino Games",
    related:"",
    path: ["/categories/dino-games"],
    thumb:"/assets/cat/prinxy-games.png",
  },
  {
    name: "Snake Games",
    related:"",
    path: ["/categories/snake-games"],
    thumb:"/assets/cat/prinxy-games.png",
  },
  {
    name: "Card Games",
    related:"",
    path: ["/categories/card-games"],
    thumb:"/assets/cat/prinxy-games.png",
  },
  {
    name: "Mahjong Games",
    related:"",
    path: ["/categories/mahjong-games"],
    thumb:"/assets/cat/prinxy-games.png",
  },
  {
    name: "Roblox Games",
    related:"",
    path: ["/categories/roblox-games"],
    thumb:"/assets/cat/prinxy-games.png",
  },
  {
    name: "New games",
    related:"",
    path: ["/categories/new-games"],
    thumb:"/assets/cat/prinxy-games.png",
  },
  {
    name: "View All",
    related:"",
    path: ["/all-categories"],
    thumb:"/assets/cat/search.png",
  },
];


