
import { useEffect } from "react";
import { CatList } from "../../src/list/Catlist";
import styles from "../../styles/CatBtn.module.css";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";

export default function CatBtn() {
  const path = useRouter();
  const pathName = path.asPath;

  function myCatLoop() {
    var myArray = [];
    var i;
    var C_length = CatList.length;
    if (pathName == "/all-categories") {
      C_length = C_length - 1;
    }else{

      C_length = 15
    }
    for (i = 0; i < C_length; i++) {
      if (i == 14 && pathName != "/all-categories") {
        myArray[CatList.length-1] = (
          // <CatLast
          //   key={i}
          //   value={CatList.length-1}
          //   urlname={CatList[CatList.length-1].path[0]}
          //   thumb={CatList[CatList.length-1].thumb}
          //   name={CatList[CatList.length-1].name}
          // />
          <CatLast
            key={CatList.length-1}
            value={CatList.length-1}
            urlname={CatList[CatList.length-1].path[0]}
            thumb={CatList[CatList.length-1].thumb}
            name={CatList[CatList.length-1].name}
          />
        );
      } else {
        myArray[i] = (
          <CatGrid
            key={i}
            value={i}
            urlname={CatList[i].path[0]}
            thumb={CatList[i].thumb}
            name={CatList[i].name}
          />
        );
      }
    }
    return myArray;
  }

  return <div className={styles.cat_grid_container}>{myCatLoop()}</div>;
}

export function CatGrid(props) {

  const myImageLoader = ({}) => {
    return `${props.thumb}?w=${10}&q=${10}`;
  };

  return (
    
    <Link href={props.urlname} prefetch={false}>
      
      <a className={`${styles.thumb_1x2} ${styles.cat_thumb}`}>
        <div
          className="thumb-container"
        >
          <div className={styles.cat_con}>
            <div className={styles.cat_part1}>
              <Image
                loader={myImageLoader}
                src={props.thumb}
                alt={props.name}
                width={75}
                height={75}
              />
            </div>
            <div className={styles.cat_part2}>{props.name}</div>
          </div>
        </div>
      </a>
    </Link>
  );
}

export function CatLast(props) {
  const myImageLoader = ({}) => {
    return `${props.thumb}?w=${100}&q=${100}`;
  };
  return (
    <Link href={props.urlname}>
    <a className={`${styles.thumb_1x2} ${styles.cat_thumb}`}>
      <div
        className="thumb-container"
      >
        <div className={styles.cat_con}>
          <div className={styles.cat_last_icon}>
            <Image
              loader={myImageLoader}
              src={props.thumb}
              alt={props.name}
              width={75}
              height={75}
            />
          </div>
          <div className={styles.cat_part2}>{props.name}</div>
        </div>
      </div>
    </a>
  </Link>
  );
}
